// import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Form from "./Form";
import FeedBack from "./FeedBack";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Form />} />
          <Route path="/feedback" element={<FeedBack />} />
        </Routes>
      </BrowserRouter>
      {/* <Form /> */}
    </>
  );
}

export default App;
