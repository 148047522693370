import React from "react";
const FeedBack = () => {
  return (
    <>
      <div style={{ marginTop: "18%", marginLeft: "20%" }}>
        <div className="card text-center" style={{ width: "50rem" }}>
          <div className="card-body">
            <h1 className="text-dark">Thank You For Register With Us.</h1>
            <br />
            <h3 className="text-success"> Our Team Will Contact You Soon.</h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeedBack;
