import "./Form.css";
import React, { useState } from "react";
import Toastify from "toastify-js";
import { useNavigate } from "react-router-dom";

const Form = () => {
  const [fileLoader, setFileLoader] = useState(false);
  const BASE_API_URL = process.env.REACT_APP_API_URL;
  const history = useNavigate();
  const [userRagistration, setUserRagistration] = useState({
    companyname: "",
    contactPerson: "",
    email: "",
    contactNumber: "",
    GSTNumber: "",
    PanCardNumber: "",
    AadharCardNumber: "",
    address: "",
    aboutVendor: "",
    password: "",
    vendorTypeID: 0,
    isActive: true,
    createBy: 0,
    ipAddress: "",
    panCardAttach: "",
    aadharCardAttach: "",
  });

  const UploadPacCard = (e) => {
    // console.log(BASE_API_URL);
    setFileLoader(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", e.target.files[0], e.target.files[0].name);
    fetch(BASE_API_URL + `/Vendor/PanCardDocumentUploadForWeb`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        setUserRagistration({ ...userRagistration, panCardAttach: data });
        setFileLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setFileLoader(false);
      });
  };

  const UploadAadharCard = (e) => {
    setFileLoader(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", e.target.files[0], e.target.files[0].name);
    fetch(BASE_API_URL + `/Vendor/AadhardCardDocumentUploadForWeb`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        setUserRagistration({ ...userRagistration, aadharCardAttach: data });
        setFileLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setFileLoader(false);
      });
  };

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserRagistration({ ...userRagistration, [name]: value });
  };

  const selectChange = (event) => {
    const value = event.target.value;
    const elementId = event.target.id;
    setUserRagistration({
      ...userRagistration,
      vendorTypeID: parseInt(value),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(userRagistration);
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userRagistration),
    };

    fetch(BASE_API_URL + `/Vendor/RegistrationVendor_By_Web`, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess == true) {
          setUserRagistration({
            companyname: "",
            contactPerson: "",
            email: "",
            contactNumber: "",
            GSTNumber: "",
            PanCardNumber: "",
            address: "",
            aboutVendor: "",
            password: "",
            vendorTypeID: 0,
            isActive: true,
            createBy: 0,
            ipAddress: "",
            aadharCardNumber: "",
            panCardAttach: userRagistration.panCardAttach,
            aadharCardAttach: userRagistration.aadharCardAttach,
          });
          setUserRagistration({
            ...userRagistration,
            aadharCardAttach: "",
            panCardAttach: "",
            aadharCardNumber: "",
            companyname: "",
            contactPerson: "",
            email: "",
            contactNumber: "",
            GSTNumber: "",
            address: "",
            aboutVendor: "",
            password: "",
          });
          // alert(data.message);
          // toast.success("Registration Successfull1");
          Toastify({
            text: "Registration Successfully",
            duration: 1000,
            stopOnFocus: true,
            className: "bg-success mt-3 text-center",
            style: {
              marginLeft: "40%",
              width: "22%",
              height: "6%",
            },
          }).showToast();
          history("/feedback");
        } else {
          alert(data.message);
          console.log(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div
        className="form-body bg-white"
        // style={{ backgroundColor: "#000000" }}
      >
        <div className="row">
          <div className="form-holder">
            <div className="form-content">
              <div className="form-items border-2 border border-dark">
                <div className="text-center d-flex mb-4">
                  <div className="d-flex col-4 me-5 mt-3">
                    <h5 className="d-block">
                      <img
                        className="d-block ms-5"
                        src={"contact.png"}
                        height={30}
                        width={30}
                      />
                    </h5>
                    <h7 className="d-block mt-1 ms-2">+91 8980531520</h7>
                  </div>{" "}
                  <img
                    className="d-block col-2 me-5"
                    src={"logo1.png"}
                    height={50}
                  />
                  <div className="d-flex col-3 mt-3">
                    <img
                      className="d-block ms-5"
                      src={"email.jpg"}
                      height={20}
                      width={20}
                    />
                    <h7 className="d-block ms-2">info@montdorinterior.com</h7>
                    {/* <h6 className="d-block">www.montdorinterior.com</h6> */}
                  </div>
                </div>
                <h3 className="text-dark text-center">Vendor Registration</h3>
                <form
                  className="requires-validation mt-3"
                  onSubmit={handleSubmit}
                  novalidate
                >
                  <div className="form-group row">
                    <label className="col-2 col-form-label col-form-label-sm text-dark">
                      Select Type :
                    </label>
                    <div className="col-4">
                      <select
                        className="form-select mt-3 border-1 border border-dark"
                        required
                        onChange={selectChange}
                      >
                        <option
                          disabled
                          value={0}
                          selected={
                            userRagistration.vendorTypeID == 0 ? true : false
                          }
                        >
                          Account Type
                        </option>
                        <option
                          value={1}
                          selected={
                            userRagistration.vendorTypeID == 1 ? true : false
                          }
                        >
                          PMC
                        </option>
                        <option
                          value={2}
                          selected={
                            userRagistration.vendorTypeID == 2 ? true : false
                          }
                        >
                          Supllier
                        </option>
                        <option
                          value={3}
                          selected={
                            userRagistration.vendorTypeID == 3 ? true : false
                          }
                        >
                          Individual
                        </option>
                      </select>
                    </div>
                    <div className="valid-feedback">
                      You selected a Account Type!
                    </div>
                    <div className="invalid-feedback">
                      Please select a Account Type!
                    </div>
                    {/* </div>

                  <div className="col-md-12 form-group row"> */}
                    <label className="col-2 col-form-label col-form-label-sm text-dark">
                      Company Name :
                    </label>
                    <div className="col-4">
                      <input
                        className="form-control border-1 border border-dark"
                        type="text"
                        name="companyname"
                        id="companyname"
                        autoComplete="off"
                        value={userRagistration.companyname}
                        onChange={handleInput}
                        placeholder="Company Name"
                        required
                      />
                      <div className="valid-feedback">
                        Company Name field is valid!
                      </div>
                      <div className="invalid-feedback">
                        Company Name field cannot be blank!
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-2 col-form-label col-form-label-sm text-dark">
                      Contact Person :
                    </label>
                    <div className="col-4">
                      <input
                        className="form-control border-1 border border-dark"
                        type="text"
                        name="contactPerson"
                        id="contactPerson"
                        autoComplete="off"
                        value={userRagistration.contactPerson}
                        onChange={handleInput}
                        placeholder="Contact Person"
                        required
                      />
                      <div className="valid-feedback">
                        Contact Person field is valid!
                      </div>
                      <div className="invalid-feedback">
                        Contact Person field cannot be blank!
                      </div>
                    </div>
                    {/* </div>

                  <div className="col-md-12 form-group row"> */}
                    <label className="col-2 col-form-label col-form-label-sm text-dark">
                      Email :
                    </label>
                    <div className="col-4">
                      <input
                        className="form-control border-1 border border-dark"
                        type="email"
                        name="email"
                        id="email"
                        autoComplete="off"
                        value={userRagistration.email}
                        onChange={handleInput}
                        placeholder="E-mail Address"
                        required
                      />
                      <div className="valid-feedback">
                        Email field is valid!
                      </div>
                      <div className="invalid-feedback">
                        Email field cannot be blank!
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-2 col-form-label col-form-label-sm text-dark">
                      Contact Number :
                    </label>
                    <div className="col-4">
                      <input
                        className="form-control border-1 border border-dark"
                        type="text"
                        name="contactNumber"
                        id="contactNumber"
                        autoComplete="off"
                        value={userRagistration.contactNumber}
                        onChange={handleInput}
                        placeholder="Contact Number"
                        required
                      />
                      <div className="valid-feedback">
                        Contact Number field is valid!
                      </div>
                      <div className="invalid-feedback">
                        Contact Number field cannot be blank!
                      </div>
                    </div>
                    {/* </div>

                  <div className="col-md-12 form-group row"> */}
                    <label className="col-2 col-form-label col-form-label-sm text-dark">
                      Password :
                    </label>
                    <div className="col-4">
                      <input
                        className="form-control border-1 border border-dark"
                        type="text"
                        name="password"
                        id="password"
                        autoComplete="off"
                        value={userRagistration.password}
                        onChange={handleInput}
                        placeholder="password"
                        required
                      />
                      <div className="valid-feedback">
                        password field is valid!
                      </div>
                      <div className="invalid-feedback">
                        password field cannot be blank!
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-2 col-form-label col-form-label-sm text-dark">
                      Pan Card Number :
                    </label>
                    <div className="col-4">
                      <input
                        className="form-control border-1 border border-dark"
                        type="text"
                        name="PanCardNumber"
                        id="Pan Card Number"
                        autoComplete="off"
                        value={userRagistration.PanCardNumber}
                        onChange={handleInput}
                        placeholder="Pan Card Number"
                        required
                      />
                      <div className="valid-feedback">
                        Pan Card Number field is valid!
                      </div>
                      <div className="invalid-feedback">
                        Pan Card Number field cannot be blank!
                      </div>
                    </div>
                    {/* </div>
                  <div className="col-md-12 form-group row"> */}
                    <label className="col-2 col-form-label col-form-label-sm text-dark">
                      Aadhar Card Number:
                    </label>
                    <div className="col-4">
                      <input
                        className="form-control border-1 border border-dark"
                        type="text"
                        name="AadharCardNumber"
                        id="Aadhar Card Number"
                        autoComplete="off"
                        value={userRagistration.AadharCardNumber}
                        onChange={handleInput}
                        placeholder="Aadhar Card Number"
                        required
                      />
                      <div className="valid-feedback">
                        Aadhar Card Number field is valid!
                      </div>
                      <div className="invalid-feedback">
                        Aadhar Card Number field cannot be blank!
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-2 col-form-label col-form-label-sm text-dark">
                      GST Number :
                    </label>
                    <div className="col-4">
                      <input
                        className="form-control border-1 border border-dark"
                        type="text"
                        name="GSTNumber"
                        id="GSTNumber"
                        autoComplete="off"
                        value={userRagistration.GSTNumber}
                        onChange={handleInput}
                        placeholder="GST Number"
                        // required
                      />
                      {/* <div className="valid-feedback">
                        GST Number field is valid!
                      </div>
                      <div className="invalid-feedback">
                        GST Number field cannot be blank!
                      </div> */}
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-2 col-form-label col-form-label-sm text-dark">
                      Address :
                    </label>
                    <div className="col-4">
                      <textarea
                        rows={2}
                        className="form-control border-1 border border-dark"
                        type="text"
                        name="address"
                        id="address"
                        autoComplete="off"
                        value={userRagistration.address}
                        onChange={handleInput}
                        placeholder="Address"
                        // required
                      ></textarea>
                      <div className="valid-feedback">
                        Address field is valid!
                      </div>
                      <div className="invalid-feedback">
                        Address field cannot be blank!
                      </div>
                    </div>
                    {/* </div>

                  <div className="col-md-12 form-group row"> */}
                    <label className="col-2 col-form-label col-form-label-sm text-dark">
                      About Vendor :
                    </label>
                    <div className="col-4">
                      <textarea
                        rows={2}
                        className="form-control border-1 border border-dark"
                        type="text"
                        name="aboutVendor"
                        id="aboutVendor"
                        autoComplete="off"
                        value={userRagistration.aboutVendor}
                        onChange={handleInput}
                        placeholder="About Vendor"
                        // required
                      ></textarea>
                      <div className="valid-feedback">
                        About Vendor field is valid!
                      </div>
                      <div className="invalid-feedback">
                        About Vendor field cannot be blank!
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-2 col-form-label col-form-label-sm">
                      <span className="text-dark">Upload Pan Card:</span>
                    </label>
                    <div className="col-10">
                      <input
                        type="file"
                        accept=".pdf,.jpeg,.jpg"
                        required
                        className="form-control form-control-lg form-control-solid border-1 border border-dark"
                        onChange={(e) => UploadPacCard(e)}
                      />
                      <div className="valid-feedback">
                        Pan Card File field is valid!
                      </div>
                      <div className="invalid-feedback">
                        Pan Card File cannot be blank!
                      </div>
                    </div>
                  </div>

                  <div className="form-group row mt-3">
                    <label className="col-2 col-form-label col-form-label-sm">
                      <span className="text-dark">Upload Aadhar Card:</span>
                    </label>
                    <div className="col-10">
                      <input
                        type="file"
                        accept=".pdf,.jpeg,.jpg,.jpg"
                        required
                        className="form-control form-control-lg form-control-solid border-1 border border-dark"
                        onChange={(e) => UploadAadharCard(e)}
                      />
                      <div className="valid-feedback">
                        Aadhar Card File field is valid!
                      </div>
                      <div className="invalid-feedback">
                        Aadhar Card File cannot be blank!
                      </div>
                    </div>
                  </div>
                  {/*  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="invalidCheck"
                      required
                    />
                    <label className="form-check-label">
                      I confirm that all data are correct
                    </label>
                    <div className="invalid-feedback">
                      Please confirm that the entered data are all correct!
                    </div>
                  </div> */}

                  <div
                    className="form-button mt-3 text-center"
                    title="Click to Registration"
                  >
                    <button
                      id="submit"
                      type="submit"
                      className="btn btn-lg btn btn-danger"
                    >
                      Register
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Form;
